<template>
  <div class="wrapper">
    <div class="flex">
      <div class="w-96 mt-4 flex items-center">
        <router-link
          :to="{ name: 'RequirementSpecifications' }"
          class="flex items-center"
        >
          <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5512 9.63624L5.6875 19.5L15.5512 29.3637"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M33.3125 19.5H5.96371"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="pl-3 text-base font-normal color-black">
            Go back
          </p>
        </router-link>
      </div>
    </div>

    <div class="title-wrap relative mt-8">
      <h3 class="page-titles">
        {{ editing ? 'Edit' : 'Create' }} requirement specification
      </h3>
      <p>
        Create and manage requirement specifications. <label
          for="how-does-it-work-modal-req"
          class="link modal-button"
        >
          How does it work?
        </label>
      </p>
      <div class="profile">
        <the-profile class="print:hidden" />
      </div>
    </div>

    <div class="w-6/12 mt-8 mb-8">
      <input
        v-model="name"
        type="text"
        @input="(showNameMsg) ? showNameMsg = false : 0"
        placeholder="Ex. Software developers"
        :class="{'input-error':showNameMsg}"
        class="w-full input input-bordered text-xl leading-7 font-normal"
      >
      <span
        v-if="showNameMsg"
        class="text-xs text-red-700"
      >* Please specify a name for your requirement specification</span>
    </div>

    <div
      class="flex flex-col w-full h-full"
    >
      <div class=" mb-6 mt-4">
        <hr class="divider-hr border-t-2">
      </div>
      <div class="grid grid-cols-1 gap-4">
        <div
          v-for="entry in Object.entries(measures)"
          :key="entry[0]"
        >
          <div class="flex flex-col mt-8 mb-4 box-shadow-radius">
            <div class="w-full table-heder-background-color drop-shadow-md rounded-tr-md rounded-tl-md">
              <h6 class="font-semibold text-xl leading-7 pt-8 color-gray pr-8 pl-8">
                {{ entry[1].label }}
              </h6>
              <p class="text-base font-normal pb-8 text-slate-500 mt-1 pr-8 pl-8 mt-4 leading-normal">
                {{ RequirementSpecification[entry[1].label] }}
              </p>
            </div>
            <div class="flex flex-row justify-between">
              <div class="w-1/2 mt-6 mb-6 pr-12 pl-8">
                <p class="text-base font-normal text-slate-500">
                  How frequently does situations occur where this ability is needed?
                </p>
                <slider-input
                  type="occurrence"
                  class="self-center mt-12 mb-6"
                  v-model.number="requirements[entry[0]].occurrence"
                  style="max-width: 650px"
                />
              </div>
              <div class="w-1/2 mt-6 mb-6 pr-12 pl-12">
                <p class="text-base font-normal text-slate-500">
                  How important is this ability?
                </p>
                <br
                  class="br-req-spec"
                >
                <slider-input
                  class="self-center mt-12 mb-6"
                  type="importance"
                  v-model.number="requirements[entry[0]].importance"
                  style="max-width: 650px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <button
            class="btn-primary float-right"
            :class="{'loading': loading}"

            :disabled="loading"
            @click="create"
          >
            {{ editing ? 'Save' : 'Create' }}
          </button>
        </div>
        <div>
          <input
            type="checkbox"
            id="how-does-it-work-modal-req"
            class="modal-toggle"
          >
          <div class="modal">
            <div class="max-w-6xl modal-box p-8">
              <h3 class="text-lg font-semibold mb-8">
                Requirement matching method
              </h3>
              <p>
                The requirement matching method is a way to convert the requirements placed on a candidate, from workplace demands to cognitive ability. The functions behind it evaluates the occurrence and importance of the ability to create a desirable profile to match against.
              </p>
              <div class="modal-action">
                <label
                  for="how-does-it-work-modal-req"
                  class="btn-outline-small"
                >Close</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>

import SliderInput from '../components/SliderInput.vue'
import { Charts, RequirementSpecification } from '@/content/reportContent.json'
import TheProfile from '@/components/TheProfile.vue'

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    SliderInput,
    TheProfile
  },
  data () {
    return {
      measures: {
        ...Charts.MainMeasures
      },
      RequirementSpecification,
      requirements: null,
      name: '',
      loading: false,
      editing: false,
      showNameMsg: false
    }
  },
  async created () {
    if (this.id) { // Editing mode
      this.editing = true

      // Get requirement specification
      const data = await this.$api.getRequirementSpecification(this.id)
      this.requirements = data.Requirements.reduce((obj, { measure, occurrence, importance, id }) => ({ ...obj, [measure]: { occurrence, importance, id } }), {})
      this.name = data.name
    } else {
      this.requirements = Object.keys(this.measures).reduce((obj, key) => ({ ...obj, [key]: { occurrence: 5, importance: 5 } }), {})
    }
  },

  methods: {
    async create () {
      this.showNameMsg = false

      if (this.name === '') {
        this.showNameMsg = true
        return
      }

      this.loading = true

      const parsedRequirements = Object.entries(this.requirements).reduce((arr, [measure, values]) => [...arr, { measure, ...values }], [])
      if (this.editing) {
        await this.$api.updateRequirementSpecification(this.id, this.name, parsedRequirements)
        this.$router.push({ name: 'RequirementSpecifications' })
      } else {
        await this.$api.createRequirementSpecification(this.name, parsedRequirements)
        this.$router.push({ name: 'RequirementSpecifications' })
      }

      this.loading = false
    }
  }

}
</script>

<style>

</style>
