<template>
  <div class="flex flex-col items-center">
    <!-- <h3>Admin {{ admin }} </h3> -->
    <input
      @input="e => $emit('input', e.target.value)"
      :value="value"
      type="range"
      :min="1"
      :max="9"
      class="w-11/12 h-4 mb-1 bg-gray-200 rounded-lg appearance-none cursor-pointer"
      :step="admin ? 0.1 : 2"
    >
    <div
      v-if="type === 'importance'"
      class="flex justify-between w-full text-xs text-center mt-8"
    >
      <span class="w-32 leading-normal">Not important at all</span>
      <span class="w-32 leading-normal">Of little importance</span>
      <span class="w-32 leading-normal">Of average importance</span>
      <span class="w-32 leading-normal">Very important</span>
      <span class="w-32 leading-normal">Absolutely essential</span>
    </div>
    <div
      v-else
      class="flex justify-between w-full text-xs text-center mt-8"
    >
      <span class="w-24 leading-normal">Very rarely</span>

      <span class="w-24 leading-normal">Rarely</span>

      <span class="w-24 leading-normal">Occasionally</span>

      <span class="w-24 leading-normal">Frequently</span>

      <span class="w-24 leading-normal">Very frequently</span>
    </div>
    <input
      @input="e => $emit('input', e.target.value)"
      :value="value"
      type="number"
      v-if="admin"
      class="w-2/12 h-16 mb-1 mt-1 bg-gray-200 rounded-lg appearance-none text-center"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      admin: false
    }
  },
  async mounted () {
    this.admin = this.$store.state.USER.user.admin
  },
  computed: {
    ...mapGetters({ isLoggedAdminIn: 'ADMIN/isLoggedAdminIn', isLoggedIn: 'USER/isLoggedIn' })
  }
}
</script>

<style>

</style>
